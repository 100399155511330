<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button variant="primary" size="sm" v-b-modal.modal-new-role>
          <BIconPlusCircle/> New Role
        </b-button>
        <b-modal id="modal-new-role" title="Create new role" hide-footer>
          <FormTemplate v-if="form" :form="form">
              <b-form-group class="label-for-xl" label="Name" label-for="inputName">
                  <b-form-input
                      type="text" id="inputName" class="form-control form-control-xl"
                      placeholder="enter the name"
                      v-model="form.data.name"
                      :state="form.states.name"
                  />
                  <b-form-invalid-feedback v-if="form.errors">{{ form.errors.name }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group class="label-for-xl" label="Placeholder" label-for="inputPlaceholder">
                  <b-form-input
                      type="text" id="inputPlaceholder" class="form-control form-control-xl"
                      placeholder="enter the name"
                      v-model="form.data.placeholder"
                      :state="form.states.placeholder"
                  />
                  <b-form-invalid-feedback v-if="form.errors">{{ form.errors.placeholder }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group class="label-for-xl" label="Parent" label-for="inputParent">
                  <b-form-select v-model="form.data.parentId" :options="roles"></b-form-select>
                  <b-form-invalid-feedback v-if="form.errors">{{ form.errors.parentId }}</b-form-invalid-feedback>
              </b-form-group>
          </FormTemplate>
        </b-modal>
      </template>
    </PageMenu>

    <div class="page-with-menu">
      <div v-if="!table.isLoading">
        <b-table class="bg-white" responsive hover :items="table.records" :fields="table.fields">
          <template #cell(name)="data">
            {{data.item.prefix}} <b-link :to='{name: "users.roles.role", params: {roleId: data.item.id}}'>{{data.item.name}}</b-link>
          </template>
          <template #cell(isDefault)="data">
            <div v-if="data.item.isDefault">
              <BIconCheckCircle variant="success"/> yes
            </div>
            <div v-else>
              <BIconX variant="secondary"/> no
            </div>
          </template>
          <template #cell(isMaster)="data">
            <div v-if="data.item.isMaster">
              <BIconCheckCircle variant="success"/> yes
            </div>
            <div v-else>
              <BIconX variant="secondary"/> no
            </div>
          </template>
          <template #cell(options)="data">
            <div class="text-right">
              <b-link class="mr-2" v-b-tooltip.hover title="Clone" @click="cloneRole(data.item)"><BIconFiles/></b-link>
              <b-link :to='{name: "users.roles.role", params: {roleId: data.item.id}}' v-b-tooltip.hover title="Edit"><BIconPencil/></b-link>
            </div>
          </template>
        </b-table>
        <PaginationRouter v-if="table.pagination.totalPages > 1" :pagination="table.pagination" :linkGen="paginationRouter"/>
      </div>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
//import moment from 'moment';
import PageMenu from '@/common/components/PageMenu';
import {BIconPlusCircle,BIconPencil,BIconCheckCircle,BIconX,BIconFiles} from 'bootstrap-vue';
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';
import PaginationRouter from '@/common/components/Pagination.Router';
import RolesTree from '@/modules/users/libs/roles.tree';
export default {
    components: {
      BIconPlusCircle,BIconPencil,BIconCheckCircle,BIconX,BIconFiles,
      PageMenu,FormTemplate,PaginationRouter
    },
    data(){
      return {
        page: {
            title: "Roles",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Users",
                url: this.$router.resolve({name: "users"}).href
            },
            {
                title: "Roles",
            }
        ],
        table: {
          isLoading: false,
          records: [],
          pagination: {
            currentPageNo: 1
          },
          fields: [
            /*
            {
              key: 'createdAt',
              label: "Created",
              formatter: (value) => {
                return moment(value).format('DD/MM/YYYY');
              }
            },
            */
            {
              key: 'name',
              label: "Name"
            },
            {
              key: 'isDefault',
              label: "Default"
            },
            {
              key: 'isMaster',
              label: "Master"
            },
            {
              key: 'options',
              label: ""
            },
          ]
        },
        roles: [],
        form: null
      }
    },
    methods: {
      paginationRouter(pageNum){
        let query =  {};
        if(pageNum > 1){
            query.page = pageNum;
        }

        return this.$router.resolve({name: 'users.roles',query: query}).href;
      },

      getResults({pageNo}){
        this.table.isLoading = true;
        this.$api.get('roles',{
          params: {
            perPage: 2000,
            page: pageNo,
            orderBy: "name",
            arrange: "asc"
          }
        }).then(({data}) => {
          this.table.isLoading = false;
          this.table.records = RolesTree(data.records);
          this.roles = [
            {value: null, text: "- top -"}
          ].concat(this.table.records.map(item => {
            return {
              value: item.id,
              text: item.prefix+" "+item.name
            }
          }));

          this.table.pagination = data.pagination;
        });
      },

      cloneRole(role){
        this.$api.post(`role/${role.id}/clone`).then(({data}) => {
          this.$router.push({name: "users.roles.role", params: {roleId: data.id}});
        })
      }
    },
    mounted(){
      this.getResults({pageNo: 1});

      this.form = new Form({name: "", placeholder: "", parentId: null},{
        onSubmit: (form) => {
          return this.$api.post('role',{
            data: {name: form.get('name'),placeholder: form.get('placeholder'), parentId: form.get("parentId")}
          }).then((res) => {
            this.$router.push({name: "users.roles.role", params: {roleId: res.data.id}});
            return res;
          });
        }
      });

      this.$emit('set-sidebar',{active: "users"});
    },

    watch: {
      "$route.query.page": function(pageNo){
        this.getResults({pageNo: pageNo});
      }
    },
};
</script>
